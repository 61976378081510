import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
// import uploadProvisionFile from './uploadProvisionFile';
import addEditCarryForward from './addEditCarryForward';
// import uploadProvisionsDetails from './uploadProvisionsDetails';
import DatePicker from 'vue2-datepicker';
import commonModalOracle from '../commonModalOracle';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'uploadProvisions',
  components: {
    DatePicker,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
    appStrings,
    // uploadProvisionFile,
    addEditCarryForward,
    // uploadProvisionsDetails,
    commonModalOracle
  },
  watch: {
    currentPage: function() {
      this.getCarryForwardData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCarryForwardData();
    }
  },
  data() {
    return {
      totalRows: null,
      currentPage: 1,
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      editMode: false,
      responseMsg: '',
      showAlert: false,
      isSuccess: false,
      carryForwardData: [],
      carryForwardFields: [
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'cf_period',
          label: 'Carry Forward Period'
        },
        {
          key: 'batch_no'
        },
        {
          key: 'batch_name'
        },
        {
          key: 'department'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'gl_posting_status',
          label: 'GL Posting Status'
        },
        {
          key: 'approval_status'
        },
      ],
      showValueSetModal: false,
      parent_value_set_id: null,
      defaultValue: {
        value: null,
        text: null
      },
      legalEntity: {
        text: null,
        value: null
      },
      batchName: null,
      period: null,
      createdBy: {
        text: null,
        value: null
      },
      approvalStatus: {
        text: null,
        value: null
      },
      glPostingStatus: {
        text: null,
        value: null
      },
      creationDate: null,
      department: {
        text: null,
        value: null
      },
      modalType: null,
      showCommonModal: false,
    //   showUploadBatchDetailsModal: false,
      showAddCarryForwardFlag: false,
      showExcelUploadModal: false,
    //   showDetailsProvisionModalFlag: false,
      rowData: null,
      provRowDetails: null,
    //   showHistoryBtn: false,
    //   showHistory: false,
    //   historyType: null,
    //   showOpenKmModal: false,
    //   openkmAuthToken: null,
    //   selectedProvisionDetails: null,
    //   showApproveRejectModal: false,
      // approvRejectType: null,
    //   approvalReason: null,
    //   rejectedReason: null,
    //   approvalRemarks: null,
    //   rejectionRemarks: null
    };
  },
  validations: {
    legalEntity: {
      text: { required }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'add' &&
          !this.showUploadBatchDetailsModal &&
          !this.showCommonModal
        ) {
          this.provRowDetails = null;
          this.showAddCarryForwardModal(true);
        } else if (actionName === 'update' || actionName === 'edit') {
          this.editMode = true;
          // this.showHideUploadBatchDetailsModal(true)
        } else if (
          actionName === 'upload' &&
          !this.showAddCarryForwardFlag &&
          !this.showCommonModal
        ) {
          // if (this.poHdrId) {
          this.showHideExcelUpload(true);
          // this.showExcelUploadModal = true;
          // } else {
          //   alert('Kindly press search button');
          // }
        }
      }
    });
  },
  methods: {
    // get api function for Provision details
    getCarryForwardData() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        batch_name: this.batchName,
        // provision_period: this.period,
        // created_by: this.createdBy.value,
        approval_status: this.approvalStatus.text,
        gl_posting_status: this.glPostingStatus.text
      };
      this.loader = true;
      this.$store
        .dispatch('provisionsAdmin/getCarryForwardData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.carryForwardData = resp.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    showHideCommonModal(flag, type) {
      this.modalType = type;
      this.showCommonModal = flag;
    },
    clear() {
      this.legalEntity = this.defaultValue;
      this.period = null;
      this.batchName = null;
      this.createdBy = this.defaultValue;
      this.approvalStatus = this.defaultValue;
      this.glPostingStatus = this.defaultValue;
      this.showHistoryBtn = false;
      this.carryForwardData = [];
      this.totalRows = null;
      this.currentPage = 1;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.UPLOAD_PROVISION_STATUS_VSET
      ) {
        this.approvalStatus = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.UPLOAD_PROVISION_STATUS_VSET
      ) {
        this.glPostingStatus = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PROVISION_EMP
      ) {
        this.createdBy = {
          value: item.user_id,
          text: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.DEPARTMENT
      ) {
        this.department = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case 'legal_entity':
          this.legalEntity = this.defaultValue;
          break;
        case 'period':
          this.period = null;
          break;
        case appStrings.VALUESETTYPE.PROVISION_EMP:
          this.createdBy = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.UPLOAD_PROVISION_STATUS_VSET:
          this.approvalStatus = this.defaultValue;
          break;
        case appStrings.VALUESETTYPE.DEPARTMENT:
          this.department = this.defaultValue;
          break;
        // case appStrings.VALUESETTYPE.UPLOAD_PROVISION_STATUS_VSET:
        //   this.glPostingStatus = this.defaultValue;
        //   break;  
      }
    },
    mapSelectedVsetData(item) {
      if (this.modalType === 'legal_entity') {
        this.legalEntity = {
          text: item.le_name,
          value: item.le_id
        };
      } else if (this.modalType === 'period') {
        this.period = item.period_name;
      }
      this.showHideCommonModal(false);
    },
    showHideUploadBatchDetailsModal(flag) {
      this.showUploadBatchDetailsModal = flag;
    },
    showAddCarryForwardModal(flag) {
      this.showAddCarryForwardFlag = flag;
      if(!flag){
        this.getCarryForwardData();
      }
    },
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
    },
    showDetailsProvisionModal(flag, item) {
      if (flag) {
        this.showDetailsProvisionModalFlag = flag;
        this.rowData = item;
      }
    },
    rowSelected(item) {
      this.provRowDetails = item;
      // this.selectedProvisionDetails = Object.assign({}, item);
      this.showAddCarryForwardModal(true);
    },
    // selectedProvision(item) {
    //   this.selectedProvisionDetails = Object.assign({}, item);
    //   this.showHistoryBtn = true;
    // },
    // showHideHistory(flag, historyType) {
    //   this.showHistory = flag;
    //   this.historyType = historyType;
    //   if(!flag){
    //     this.getCarryForwardData();
    //   }
    // },
    // validateUploadDocument() {
    //     this.showHideHistory(true, 'sendForApproval');
    // },
    // checkOpenKmPasswordSaved() {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/checkSavedPassword')
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         if (response.data.data) {
    //           this.getOpenKmAuthToken();
    //         } else {
    //           this.showHideOpenKmModal(true);
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // getOpenKmAuthToken() {
    //   const payload = {
    //     password: null,
    //     saved_password_flag: true,
    //     user_name: null
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/openKmAuthenticate', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         this.openkmAuthToken = response.data.data.token;
    //         // this.selectedGridData = {
    //         //   ...this.selectedProvisionDetails,
    //         //   nfa_cat_vset: null,
    //         //   nfa_cat: null,
    //         //   nfa_sub_cat_vset: null,
    //         //   nfa_sub_cat: null
    //         // };
    //         this.showHideHistory(true, 'uploadDocument');
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // showHideOpenKmModal(flag) {
    //   this.showOpenKmModal = flag;
    // },
    // setOpenKmToken(token) {
    //   this.showHideOpenKmModal(false);
    //   this.openkmAuthToken = token;
    //   // this.selectedGridData = {
    //   //   ...this.selectedProvisionDetails,
    //   //   nfa_cat_vset: null,
    //   //   nfa_cat: null,
    //   //   nfa_sub_cat_vset: null,
    //   //   nfa_sub_cat: null
    //   // };
    //   this.showHideHistory(true, 'uploadDocument');
    // },
    // showHideApproveRejectModal(flag, type) {
    //   this.showApproveRejectModal = flag;
    //   this.approvRejectType = type;
    // },
    // approveRejectProvisons(status) {
    //   const payload = {
    //     event_id: this.selectedProvisionDetails.event_id,
    //     wf_inprocess_dtl_id: this.selectedProvisionDetails.wf_inprocess_dtl_id,
    //     notification_status: status,
    //     notification_comments:
    //       status === 'REJECTED' ? this.rejectedReason : this.approvalReason
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/approvalRejectSingle', payload)
    //     .then(response => {
    //       this.loader = false;
    //       this.showAlert = true;
    //       if (response.status === 200) {
    //         // this.getVendorInvoiceDetailsById();
    //         this.editMode = false;
    //         this.isSuccess = true;
    //         this.responseMsg = response.data.message;
    //         if (status === 'REJECTED') {
    //           this.showHideApproveRejectModal(false);
    //         } else if (status === 'APPROVED') {
    //           this.showHideApproveRejectModal(false);
    //         }
    //         // this.$emit('updateInvoice');
    //       } else {
    //         this.showAlert = false;
    //         alert(response.response.data.message);
    //         // this.isSuccess = false;
    //         // this.responseMsg = response.response.data.message;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};